import DataSource from '@/api/datasource/datasource'
import { IDatasource, IGetRowsParams } from 'ag-grid-community'
import AdminAPI from '@/api/requests/admin'

export class SessionsDataSource extends DataSource implements IDatasource {
  getCastCols() {
    return {
      user: 'user.surname'
    }
  }

  async getRows(params: IGetRowsParams) {
    const limit = params.endRow - params.startRow
    const offset = params.startRow
    const sort = this.castSortModel(params.sortModel)
    const filter = this.castFilterModel(params.filterModel)

    const response = await AdminAPI.getSessions(limit, offset, sort, filter)
    params.successCallback(response.data.rows, response.data.count)
  }
}
