








import { Component, Vue } from 'vue-property-decorator'
import MainCard from '@/components/ui/MainCard.vue'
import SessionsTable from '@/components/admin/SessionsTable.vue'

@Component({ components: { MainCard, SessionsTable } })
export default class Sessions extends Vue {}
