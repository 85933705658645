










import { AgGridVue } from 'ag-grid-vue'
import { Component, Vue } from 'vue-property-decorator'
import {
  GridOptions,
  GridApi,
  ColDef,
  CellClickedEvent,
  ValueGetterParams,
  ValueFormatterParams
} from 'ag-grid-community'
import moment from 'moment'
import { BModal } from 'bootstrap-vue'

import AgGridFactory from '@/factories/agGridFactory'
import { SessionsDataSource } from '@/api/datasource/admin/adminSessions'
import dateFormat from '@/config/default/dateFormat'
import { getUserText } from '@/utils/formatters'

@Component({ components: { AgGridVue } })
export default class SessionsTable extends Vue {
  private sessionData: any

  $refs!: { sessionModal: BModal }

  private gridOptions: GridOptions = {
    ...AgGridFactory.getDefaultGridOptions(),
    datasource: new SessionsDataSource(),
    rowModelType: 'infinite',
    pagination: true,
    paginationPageSize: 20,
    onGridReady: this.onGridReady,
    onCellClicked: this.onCellClicked
  }

  private columnDefs: ColDef[] = [
    {
      colId: 'user',
      valueGetter(params: ValueGetterParams) {
        if (params.data) {
          return getUserText(params.data.passportUser)
        }
        return ''
      },
      headerName: 'Пользователь',
      minWidth: 100
    },
    {
      headerName: 'Последнее обновление',
      field: 'updatedAt',
      colId: 'updatedAt',
      width: 200,
      suppressSizeToFit: true,
      valueFormatter(params: ValueFormatterParams) {
        if (params.value) {
          return moment(params.value).format(dateFormat.date.dateTime)
        }
        return ''
      },
      cellClass(params) {
        if (params.value) {
          const val = moment(params.value)
          const now = moment()
          if (now.diff(val, 'minutes') < 5) {
            return 'cellActive'
          } else if (now.diff(val, 'hours') < 1) {
            return 'cellLastHour'
          } else {
            return 'cellOffline'
          }
        }
        return ''
      },
      filter: 'agDateColumnFilter'
    },
    {
      headerName: 'Истекает',
      field: 'expires',
      colId: 'expires',
      width: 200,
      suppressSizeToFit: true,
      valueFormatter(params: ValueFormatterParams) {
        if (params.data) {
          return moment(params.value).format(dateFormat.date.dateTime)
        }
        return ''
      },
      filter: 'agDateColumnFilter'
    }
  ]

  private gridApi?: GridApi

  private onGridReady({ api }: { api: GridApi }) {
    this.gridApi = api
    api.sizeColumnsToFit()
  }

  private onCellClicked(params: CellClickedEvent) {
    if (params.data.stacktrace) {
      this.sessionData = params.data.passportUser
      this.$refs.sessionModal.show()
    }
  }
}
