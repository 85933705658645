





































import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class MainCard extends Vue {
  @Prop({ type: String, required: true }) private readonly name!: string

  @Prop({ type: String, required: false })
  private readonly tooltipStatus!: string
  @Prop({ type: Array, required: false }) private readonly icons!: string[][]

  @Prop({ type: Boolean, default: false }) private readonly noflex!: boolean
  @Prop({ type: Boolean, default: false }) private readonly 'no-body'!: boolean

  @Prop({ type: Boolean, default: false }) private readonly linkIcon!: boolean
}
